import axios from "axios";
import { publicPath } from "@/common/constant.js";
import {
  getdictInfo,
  getRole,
  getdaiabnInfo,
  getmessageInfo,
  getmarket,
  getAccountNum,
} from "./api";
import addressSelect from "@/views/basicInformation/components/addressSelct/addressSelect";
import { personnelRecordsManagement } from "../businessPersonnelManagement/api"
import deleteModal from  "./../DeleteModal"
import  mangeStore from './../mangeStore'
import { flushAccountRegionShop } from "@/views/basicInformation/api";
import officePop from "@/views/basicInformation/components/officePop";
export default {
  name: "index",
  components:{
    addressSelect,
    deleteModal,
    mangeStore,
    officePop
  },
  data() {
    return {
      // 面包屑数据
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/basicInfo?type=1",
          name: "basicInfo?type=1",
          title: "人员账号管理"
        },
        {
          path: "/addPerson",
          name: "addPerson",
          title: '新增用户账号'
        }
      ],
      //===========================业务人员相关===============================//
      //业务人员表头列
      columns: [
        {
          label: '档案编号',
          prop: 'businessFileCode',
          width: 181,
          scopedSlots: { customRender: 'businessFileCode' },
          ellipsis: true,
          slot: 'businessFileCode'
        },
        {
          label: '姓名',
          prop: 'name',
          width: 140,
          ellipsis: true,
          scopedSlots: { customRender: 'name' },
        },
        {
          label: '联系电话',
          prop: 'mobile',
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: 'mobile' },
        },
      ],
      //表格数据
      tableData: [],
      //分页 -> 数量很少 暂时不需要
      pager: {
        pageNo: 1,
        pageSize: 999,
        count: 0
      },
      personId: '',//选中的人员档案id
      businessFlag: false,//是否是业务人员
      //===========================业务人员相关End===============================//


      // 销售组织数据
      baseMatklList: [
        {
          id: 0,
          matklName: '全部',
          isCheck: false
        }
      ], // 物料组
      loginSystemList: [],// 登陆系统
      isSaleMan: 1,
      accountType: '用户账号',
      accountName: '',
      name: '',
      phone: '',
      eamil: '',
      saleName: undefined,
      contacts: '',
      shopName: undefined,
      SystemName: undefined,
      baseMatkName: undefined,
      materialGroupGroup: [],
      materialGroup: [],
      canChange: true,
      isActiveLevel: 0,
      problemLevel: 0,
      accountid: '',
      spinning: false,
      spinning2: false,
      showText: false,
      showTextE: false,
      roleList: [
        {
          id: 0,
          name: '全部',
          isCheck: false
        }
      ],
      ckName: [],
      ckList: [
        {
          id: 0,
          name: '全部',
          isCheck: false
        }
      ],
      positionList: [], // 岗位数据
      firstPostionList: [],// 初始化岗位数据
      firstPostionArr: [],
      isActivePLevel: '',
      positinLevel: 0,
      isActiveRLevel: 0,
      roleLevel: 0,
      nocticeList: [
        {
          code: 0,
          name: '全部',
          isCheck: false
        }
      ],// 通知列表
      isActiveNoticeLevel: 0,
      NoticeLevel: 0,
      todoList: [
        {
          code: 0,
          name: '全部',
          isCheck: false
        }
      ],// 通知列表

      isActivetodoLevel: 0,
      todoLevel: 0,
      addressHx: [],
      addressArrGroup: [],
      addressNameArr: [],
      isShowShop: false,
      visibleshop: false,
      mesageInfoTips: '',
      fetching: false,
      AssignOrViewVisible: false,
      markrtIds: [],
      accountHisenseType: false,
      changeNum: 0,
      bindCachePosts: [],
      filterPosts: ['14181287851', '14181287853'],
      // 运营经理岗位列表
      operateMangePosts: ['14181287852', '14181287855'],
      // 操盘手
      traderPosts: ['14181287847'],
      // 下沉
      sinkPosts: ['17594638392'],
      // 自有渠道
      ownChannelGridManager: ['20231110'],
      officePopups:false,
      ldapUserId:'',
      marketCenterIds:'',
      checkRowId:''

    };
  },
  mounted() {
    this.accountid = this.$route.query.shopId
    this.getInfo()
    this.getPersonList();
    // 如果是代理运营商(登录接口中isAgentOperator判断) 岗位中只有财务人员  平台支持人员  不展示管辖区域和管辖门店
    // 如果是普通代理商 分销商 不需要展示管辖门店和管辖区域
     if(this.$store.state.user.userInfo.isAgentOperator == 0) {
        this.isShowShop = false
     } else {
       this.isShowShop = true
     }

  },
  methods: {
    // 选择的办事处经理和营销中心
    getOffice(obj){
      this.ldapUserId = obj.ldapUserId
      this.marketCenterIds = obj.marketCenterIds
    },
    // 清缓存
    clear() {
      flushAccountRegionShop({account:this.accountName }).then(res => {
      })
    },
    openAssignOrViewVisible(){
      if (this.firstPostionArr.length <= 0 ) {
        this.$message.warning('请选择岗位!')
        return
      }
      if(!this.personId ){
        this.$message.warning('请选择人员档案')
        return false
      }
      if (this.markrtIds.length <= 0 ) {
        this.$message.warning('请选择物料组!')
        return
      }
      this.AssignOrViewVisible = true
      this.changeNum++
    },

    // 判断档案和岗位是否都是网格经理  如果当前页面的岗位选择了自有渠道网格经理但是选择的档案里的岗位不是网格经理的话 就弹出提示
    isgridManager(){
      if (this.firstPostionArr.indexOf('20231110') !== -1 && this.checkRowId !== 20231110){
        return true
      }else{
        return false
      }
    },
    //===================业务人员相关===================//
    // 选择项更改
    onChange(selRows) {
      this.checkRowId = selRows?selRows[0].jobsId:''
      if(this.isShowShop) {
        if (selRows.length>0 && this.personId && selRows[0].id != this.personId ) {
          this.$message.warning('档案已变更，请重新选择管辖区域和管辖门店。')
          this.clear()
        }
      }

      this.baseMatklList.forEach(item => {
        item.isCheck = false
      })
      const matklIdsArr =  selRows&&selRows.length>0?selRows[0].matklIds.split(','):'';
      const CheckArr = []
      for (let item of this.baseMatklList) {
        for (const maItem of matklIdsArr) {
          if(item.id == maItem){
            item.isCheck = true;
            CheckArr.push(item)
          }
        }
      }
      //人员档案id -> 提交传参用
      this.personId =  selRows[0]?selRows[0].id:'';
      this.markrtIds = []
      this.baseMatklList.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          this.markrtIds.push(item.id)
        }
      })

      if(
        this.markrtIds.includes(20160119008)  || this.markrtIds.includes(20160119013) ||
        this.markrtIds.includes(20160119014) ||this.markrtIds.includes(20160119018)  ||
        this.markrtIds.includes(20160119020) ||this.markrtIds.includes(20160119022)
      ) {
        this.accountHisenseType = true
      } else {
        this.accountHisenseType = false
      }
      // 全部按钮赋值
      if((this.baseMatklList.length-1) === CheckArr.length){
        this.baseMatklList[0].isCheck = true;
      }else{
        this.baseMatklList[0].isCheck = false;
      }
      if(this.isgridManager()){
        this.officePopups = true
        return
      }
    },
    async getPersonList() {
        const data = {
            pageNo:this.pager.pageNo,
            pageSize: this.pager.pageSize
        }
        const res = await personnelRecordsManagement(data)
        this.tableData = res.data.list
        // this.tableData = util.data.list;
        this.pager.pageNo = res.data.currentPage
        this.pager.pageSize = res.data.pageSize
        this.pager.count = res.data.totalRows
    },

     // 编辑表格
     editTble(selRows) {
      this.$router.push({
        path: "/businessAddEdit",
        query: {
          id: selRows.id,
          isNoShenPi:true,
          jobsName: selRows.jobsName,//岗位
          custFullName: selRows.custFullName,//商家名称|代理商全称
          cisCode: selRows.cisCode,//CIS编码
          custAccountName: selRows.custAccountName,//账号名称|用户账号
          recordType: selRows.recordType,
          source:'person'
        },
      });
    },
    //===================业务人员相关End===================//

    // 验证手机号是否正确
    changePhone(e) {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;    //11位手机号码正则

      if (!reg_tel.test(e.target.value)) {
        this.showText = true
        return false
      } else {
        this.showText = false
      }

    },
    changeEmail(e) {
      var re = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
      if (!re.test(e.target.value)) {
        this.showTextE = true
        return false
      } else {
        this.showTextE = false
      }
    },
    handCk(data) {
      this.ckName = this.checkAll(data, this.ckList)
    },
    //是否根据输入项进行筛选
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    clearStoreItem() {
      this.ckName = []
    },
    checkAll(arr, modelList) {
      // arr:change中的数组 ，  modelList:下拉框List
      let length = arr.length
      let list = arr
      arr.forEach(element => {
        // 当数组中存在0，说明此时进行全选/取消全选
        if (element == '0') {
          // 当数组长度为最大长度且最后一个元素为0时，说明此时在全选的基础上又点击全选，则取消全选
          if (length - 1 == modelList.length && arr[0] == '0') {
            list = []
          } else {
            // 当不是取消全选操作，只要数组中出现了0则说明进行了全选操作
            list = []
            for (let i in modelList) {
              if (modelList[i].id !== 0) {
                list.push(modelList[i].id)
              }

            }
          }
        }
      })
      return list
    },
    goback() {
      // 返回人员管理页面
      this.$router.push({
        path: '/basicInfo',
        query: {
          type: 1
        }
      })
    },
    // 获取物料组 管辖门店
    getInfo() {
      this.spinning = true
      let data = {}
      getAccountNum(data).then(res => {
        if(res.data && res.data.account) {
          this.accountName = res.data.account
          this.clear()
        } else {
          this.$message.warning('生成账号失败',res.data.msg)
        }
        this.spinning = false
      })
      this.getPosition()
      this.getRoleList()
      this.getTodo()
      this.getNotice()
      this.getMrketList()

    },
    // 获取门店物料
    getMrketList() {
      let data = {}
      this.spinning2 = true;
      getmarket(data).then(res => {
        this.spinning2 = false;
        res.data.data.baseMatklList.forEach(item => {
          item.isCheck = false
        })
        this.baseMatklList.push(...res.data.data.baseMatklList)
      })

    },
    getRoleList() {
      let data = {
        custId: this.$store.state.user.userInfo.customer.id,

      }

      getRole(data).then(res => {
        res.data.data.roleList.forEach(item => {
          item.isCheck = false
        })
        this.roleList.push(...res.data.data.roleList)

        res.data.data.shopCkList.forEach(item => {
          item.isCheck = false
        })
        this.ckList.push(...res.data.data.shopCkList)
      })
    },
    btnroleClickLevel(index, id, item) {
      this.isActiveRLevel = index
      this.roleLevel = id
      item.isCheck = !item.isCheck

      if (index == 0 && item.isCheck == true) {
        this.roleList.forEach(item => {
          item.isCheck = true
        })
      }
      if (index == 0 && item.isCheck == false) {
        this.roleList.forEach(item => {
          item.isCheck = false
        })
      }
    },
    // 获取岗位
    getPosition() {
      getdictInfo().then(res => {
        res.data.list.forEach(item => {
          item.isCheck = false
        })
        // 获取全部岗位
        this.positionList.push(...res.data.list)
        this.firstPostionList.push(...res.data.list)
        // 如果非代理运营商  岗位筛除代理运营商
        if(this.$store.state.user.userInfo.isAgentOperator == 0){
          this.firstPostionList = this.firstPostionList.filter(function(obj) {
            return obj.code !== '20231110';
          });
        }
      })
    },
    // 按钮点击样式切换info
    btnClickLevel(index, id, item) {
      if(this.businessFlag){
        this.$message.info('业务人员物料组根据档案信息获取，点击档案编号可修改',3);
        return
      }
      this.isActiveLevel = index
      this.problemLevel = id
      item.isCheck = !item.isCheck

      if (index == 0 && item.isCheck == true) {
        this.baseMatklList.forEach(item => {
          item.isCheck = true
        })
      }
      if (index == 0 && item.isCheck == false) {
        this.baseMatklList.forEach(item => {
          item.isCheck = false
        })
      }
      this.markrtIds = []
      this.baseMatklList.forEach(itemMatk => {
        if (itemMatk.isCheck == true && itemMatk.id !== 0) {
          this.markrtIds.push(itemMatk.id)
        }
      })
      if(
        this.markrtIds.includes(20160119008)  || this.markrtIds.includes(20160119013) ||
        this.markrtIds.includes(20160119014) ||this.markrtIds.includes(20160119018)  ||
        this.markrtIds.includes(20160119020) ||this.markrtIds.includes(20160119022)
      ) {
        this.accountHisenseType = true
      } else {
        this.accountHisenseType = false
      }
    },
    getArrDifference(arr1, arr2) {
      if (!arr1 || arr1.length === 0) {
        return []
      }
      let list= [];
      list = arr1.filter(item=>{
        return arr2.indexOf(item) === -1
      });
      return list
    },
    getPostionArr(value) {
      this.bindCachePosts = value
    },
    // 按钮点击样式切换
    btnPostionClickLevel(index, id, item) {
      // this.clear()
      this.positinLevel = []
      this.isActivePLevel = id
      item.isCheck = !item.isCheck
      this.$forceUpdate()
      this.positinLevel.push(id)
      if(item.isCheck ) {
        // 操盘手不可以与其他运营经理岗位同时选择；下沉经理不可以与运营经理相关岗位同时选择
        if (!this.judgePostsCompatible(this.firstPostionArr, id)) {
          this.$message.warning('操盘手不可以与其他运营经理岗位同时选择;下沉经理不可以与运营经理相关岗位同时选择;非下沉渠道网格经理不可以与运营经理及下沉经理岗位同时选择;')
          item.isCheck = false
          return
        }
        this.firstPostionArr.push(id)
        // 筛除以后的数据
        if (!this.judgePostsCompatible(this.bindCachePosts, id)) {
          this.clear()
          this.bindCachePosts = []
        }
      } else {
        // 如果选中又取消 就删除对应的数据
        this.firstPostionArr.splice( this.firstPostionArr.indexOf(id),1)
      }

      // 如果选中的数据有业务员的选项就弹出业务员数据
      if (this.firstPostionArr.indexOf("14181287847") !== -1
        || this.firstPostionArr.indexOf('14181287852') !== -1
        || this.firstPostionArr.indexOf('14181287855') !== -1
        || this.firstPostionArr.indexOf('17594638392') !== -1
        || this.firstPostionArr.indexOf('20231110') !== -1
      ) {
        this.businessFlag = true
      } else if(this.firstPostionArr.indexOf('14181287851') !== -1
        || this.firstPostionArr.indexOf('14181287853') !== -1){
        this.personId = '';//清空人员档案id
        this.businessFlag = false
        this.baseMatklList.forEach(item => {
          item.isCheck = false
        });
      } else {
        this.personId = '';//清空人员档案id
        this.businessFlag = false
      }
      if( (
        // this.firstPostionArr.indexOf('14181287847') !== -1||
        this.firstPostionArr.indexOf('14181287852') !== -1
          || this.firstPostionArr.indexOf('14181287855') !== -1
          || this.firstPostionArr.indexOf('17594638392') !== -1
        )
        && this.$store.state.user.userInfo.isAgentOperator == 1 ){
        this.isShowShop = true
      } else {
        this.isShowShop = false
      }

    },
    // 判断兼容
    judgePostsCompatible(selectedPosts, postCode) {
      if (this.$store.state.user.userInfo.isAgentOperator !== '1') {
        return true
      }
      if (!selectedPosts || selectedPosts.length === 0) {
        return true
      }
      if (this.filterPosts.includes(postCode)) {
        return true
      }
      if ((this.judgePostsIncludes(selectedPosts, this.operateMangePosts) && this.operateMangePosts.includes(postCode))
        || (this.judgePostsIncludes(selectedPosts, this.traderPosts) && this.traderPosts.includes(postCode))
        || this.judgePostsIncludes(selectedPosts, this.sinkPosts) && this.sinkPosts.includes(postCode)
        || this.judgePostsIncludes(selectedPosts, this.ownChannelGridManager) && this.ownChannelGridManager.includes(postCode)
      ) {
        return true
      }
      return false
    },
    // 判断岗位包含关系
    judgePostsIncludes(selectedPosts, posts) {
      let filterCount = 0;
      for (let i = 0; i < selectedPosts.length; i++) {
        if (this.filterPosts.includes(selectedPosts[i])) {
          filterCount ++;
        }
        if (posts.includes(selectedPosts[i])) {
          return true
        }
      }
      if (filterCount === selectedPosts.length) {
        return true
      }
      return false
    },
    // 获取待办 
    getTodo() {
      getdaiabnInfo().then(res => {
        if (res.data.list.length > 0) {
          res.data.list.forEach(item => {
            item.isCheck = false
          })
          this.todoList.push(...res.data.list)
        } else {
          this.todoList = []
        }
      })
    },
    btnTodoClickLevel(index, id, item) {
      this.isActivetodoLevel = index
      this.todoLevel = id
      item.isCheck = !item.isCheck

      if (index == 0 && item.isCheck == true) {
        this.todoList.forEach(item => {
          item.isCheck = true
        })
      }
      if (index == 0 && item.isCheck == false) {
        this.todoList.forEach(item => {
          item.isCheck = false
        })
      }
    },
    // 获取通知选项
    getNotice() {
      getmessageInfo().then(res => {
        if (res.data.list.length > 0) {
          res.data.list.forEach(item => {
            item.isCheck = false
          })
          this.nocticeList.push(...res.data.list)
        } else {
          this.nocticeList = []
        }
      })
    },
    btnnoticeClickLevel(index, id, item) {
      this.isActiveNoticeLevel = index
      this.NoticeLevel = id
      item.isCheck = !item.isCheck

      if (index == 0 && item.isCheck == true) {
        this.nocticeList.forEach(item => {
          item.isCheck = true
        })
      }
      if (index == 0 && item.isCheck == false) {
        this.nocticeList.forEach(item => {
          item.isCheck = false
        })
      }
    },
    focus() {
      if(this.businessFlag){
        if(this.addressNameArr.length <= 0 ) {
          this.$message.warning('请选择管辖区域')
          return
        }
      }
    },
    // 确定提交
    sumbmit() {
      if(this.isgridManager()&&(!this.ldapUserId||!this.markrtIds)){
        this.officePopups = true
        return
      }
      if(this.$store.state.user.userInfo.isAgentOperator == 1 &&
       ( this.firstPostionArr.indexOf('14181287847') !== -1  &&
        (this.firstPostionArr.indexOf('17594638392') !== -1 || this.firstPostionArr.indexOf('14181287852') !== -1
        || this.firstPostionArr.indexOf('14181287855') !== -1))){
        this.$message.warning('操盘手不可以与其他运营经理岗位同时选择；下沉经理不可以与运营经理相关岗位同时选择')
        return
      }
      if(this.$store.state.user.userInfo.isAgentOperator == 1 &&
        (this.firstPostionArr.indexOf('17594638392') !== -1  &&
        (this.firstPostionArr.indexOf('14181287847') !== -1 || this.firstPostionArr.indexOf('14181287852') !== -1
        || this.firstPostionArr.indexOf('14181287855') !== -1))){
        this.$message.warning('下沉经理岗位不可以与运营经理相关岗位同时选择')
        return
      }
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;    //11位手机号码正则
      if(!this.businessFlag){
        if(this.name == '') {
          this.$message.warning('请填写名称!')
          return false
        }
        if (!reg_tel.test(this.phone)) {
          this.showText = true
          this.$message.warning('请填写正确的手机号')
          return false
        }
        var re = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
        if (!re.test(this.eamil)) {
          this.showTextE = true
          this.$message.warning('请填写正确的邮箱')
          return false
        }
      }else{
        if(!this.personId){
          this.$message.warning('请选择人员档案')
          return false
        }
        
      }


    this.baseMatklList.forEach(item => {
      if (item.isCheck == true && item.id !== 0) {
        this.materialGroup.push(item.id)
      }
    })
    let noticeCherk = []
    this.nocticeList.forEach(item => {
      if (item.isCheck == true && item.code !== 0) {
        noticeCherk.push(item.code)
      }
    })
    let tocoCherk = []
    this.todoList.forEach(item => {
      if (item.isCheck == true && item.code !== 0) {
        tocoCherk.push(item.code)
      }
    })
    let roleCherk = []
    this.roleList.forEach(item => {
      if (item.isCheck == true && item.id !== 0) {
        roleCherk.push(item.id)
      }
    })

    let positionCherk = []
    this.positionList.forEach(item => {
      if (item.isCheck == true && item.code !== 0) {
        positionCherk.push(item.code)
      }
    })


    let markrtCherk = []
    this.baseMatklList.forEach(item => {
      if (item.isCheck == true && item.id !== 0) {
        markrtCherk.push(item.id)
      }
    })
    if (this.firstPostionArr.length <= 0 ) {
      this.$message.warning('请选择岗位!')
      return
    }
    if (markrtCherk.length <= 0) {
      this.$message.warning('请选择物料组!')
      return
    }
    if (roleCherk.length <= 0) {
      this.$message.warning('请选择角色!')
      return
    }
   
    if (noticeCherk.length <= 0) {
      this.$message.warning('请选择通知!')
      return
    }
    if (tocoCherk.length <= 0) {
      this.$message.warning('请选择待办!')
      return
    }
      let formData = new FormData()

      formData.append('categorySeries', this.accountHisenseType?'1':'0'); // 是否是海信系
      formData.append('name', this.name);
      formData.append('phone', this.phone);
      formData.append('email', this.eamil);
      formData.append('shopIds', []);
      formData.append('businessFlag', this.businessFlag ? 1 : 0);
      formData.append('custAccountId', this.accountid);
      formData.append('roleIds', roleCherk);
      formData.append('matklIds', markrtCherk);
      formData.append('noticeIds', noticeCherk);
      formData.append('dealIds', tocoCherk);
      formData.append('workIds', this.firstPostionArr);
      formData.append('account', this.accountName);
      formData.append('wareIds', this.ckName);
      formData.append('personId', this.personId);
      formData.append('marketCenterIds', this.marketCenterIds);
      formData.append('ldapUserId', this.ldapUserId);
      let config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      // 发送请求
      const BASE_API = publicPath;

      this.spinning = true;
      axios.post(BASE_API + '/customer/addUser.nd', formData, config).then(res => {
        if (res.data.code == 0) {
          this.$message.info(res.data.msg)
          this.spinning = false;
          // 返回人员管理页面
          this.$router.push({
            path: '/basicInfo',
            query: {
              type: 1
            }
          })
        } else if (res.data.code == 400 ){
          this.spinning = false;
        } else  {
          this.$message.warning(res.data.msg)
          this.spinning = false;
        }
      }).catch(res=>{

        this.$message.warning(res.data.msg)
        this.spinning = false;
      })
    },
  }
}