<template>
  <a-cascader
    :style="setStyle()"
    v-model="addressVal"
    change-on-select
    :options="addressData"
    :load-data="loadData"
    placeholder="请选择省、市、区"
    @popupVisibleChange="popupVisibleChange"
    @change="onChange"
    v-bind="$attrs"
  />
</template>

<script>
//获取联动地区组织
import { getRegin, getReginById } from "@/utils/api";
export default {
  name: "address-from",
  data() {
    return {
      fisrtOpenFlag: false,
      addressTargetId: "",
      addressVal: [],
      addressValName:'',
      addressData: [],
    };
  },
  props: {
    width: {
      type: [Number, String],
      default: 84,
    },
    height: {
      type: [Number, String],
      default: 28,
    },
    addressDftVal: {
      type: Array,
      default: () => [],
    },
    type: {
      type: [Number, String],
      default: 1,
    },
  },
  watch: {
    //编辑状态下 传入code数组 -》 进行默认值赋值操作
    addressDftVal: {
      handler() {
        // if (this.addressDftVal && this.addressDftVal.length > 3) {
        //   this.addressVal = this.addressDftVal; //this.addressDftVal.slice(1, this.addressDftVal.length);
        //   this.fisrtOpenFlag = true;
        //   this.editInit();
        // } else {
        //   this.addressVal = [];
        // }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {},
  mounted() {},
  methods: {
    setStyle() {
      let style = {};
      style.width = this.width + "px";
      style.height = this.height + "px";
      return style;
    },

    //编辑时初始化数据
    async editInit() {
      try {
        //获取根据地区code请求的整合数组数据
        const parr = [...["0"], ...this.addressDftVal].map((item) => {
          return new Promise((resolve, reject) => {
            let dataArr = [];
            if (this.type == 1) {
              getRegin({ pCode: item, level: 3 }).then((re) => {
                if (re && re.data && re.data.code == 0) {
                  dataArr = re.data.list.map((item) => {
                    return {
                      value: item["code"],
                      label: item["name"],
                      isLeaf: item["desc"] == "child" ? true : false,
                    };
                  });
                }
                resolve(dataArr);
              });
            } else {
              getReginById({ pid: item, level: 3 }).then((re) => {
                if (re && re.data && re.data.code == 0) {
                  dataArr = re.data.list.map((item) => {
                    return {
                      value: item["code"],
                      label: item["name"],
                      isLeaf: item["desc"] == "child" ? true : false,
                    };
                  });
                }
                resolve(dataArr);
              });
            }
          });
        });
        const res = await Promise.all(parr).then((res_) => {
          //建立父级地区编码与请求子地区数据对应关系 -> 放便整理后续数据格式
          let dftObj = {};
          //拼装最上级编码
          const dftCodeArr = [...["0"], ...this.addressDftVal];
          for (const idx in dftCodeArr) {
            dftObj[dftCodeArr[idx]] = res_[idx];
          }
          //寻找下级，有下级则增加children
          const fun = (item) => {
            for (const itemCode in dftObj) {
              if (item.value == itemCode) {
                if (dftObj[itemCode] && dftObj[itemCode].length > 0) {
                  item.children = dftObj[itemCode];
                  for (const item_ of item.children) {
                    fun(item_);
                  }
                }
              }
            }
          };
          for (const item of res_[0]) {
            fun(item);
          }
          //获得格式化后的数据
          this.addressData = res_[0];
        });
      } catch (e) {
        console.log(e);
      }
    },
    // 接口联动
    async loadData(selectedOptions) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      let res_ = null;
      if (this.type == 1) {
        res_ = await getRegin({ pCode: targetOption.value, level: 3 });
      } else {
        res_ = await getReginById({ pid: targetOption.value, level: 3 });
      }
      if (res_.data.code == 0) {
        targetOption.loading = false;
        targetOption.children = res_.data.list.map((item) => {
          return {
            value: item["code"],
            label: item["name"],
            isLeaf: item["desc"] == "child" ? true : false,
          };
        });
        //更新数据
        this.addressVal = [...this.addressVal];
      }

      // });
    },
    // 地址弹窗打开关闭
    async popupVisibleChange(isOpen) {
      if (isOpen == false && this.addressVal.length < 3) {
        this.addressVal = [];
        this.$emit("onAddressChange", []);
        return;
      }
      //第一次打开 或者 清空后无数据 --》请求数据
      if (isOpen && (!this.fisrtOpenFlag || !this.addressVal)) {
        //定义成数组类型
        this.fisrtOpenFlag = true;
        let res_ = null;
        //弹开
        if (this.type == 1) {
          res_ = await getRegin({ pCode: 0 });
        } else {
          res_ = await getReginById({ pid: 0 });
        }
        // getRegin({ pCode: 0 }).then(res => {
        if (res_.data.code == 0) {
          this.addressData = res_.data.list.map((item) => {
            return {
              value: item["code"],
              label: item["name"],
              isLeaf: false,
            };
          });
        }

        // });
      } else {
        // this.fisrtOpenFlag = false;
      }
    },

    // 选择后触发
    onChange(value,selectedOptions) {
      if (value.length > 2 || value.length == 0) {
        this.addressValName = selectedOptions[0].label+selectedOptions[1].label+selectedOptions[2].label
        this.$emit("onAddressChange", this.addressVal,this.addressValName);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@blue: #1890ff;
@green: #00aaa6;
.ant-cascader-picker {
  /deep/input {
    width: 100%;
    height: 100%;
  }
}
</style>
