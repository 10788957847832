<template>
  <a-modal 
    v-model="visible" 
    @ok="handleOk" 
    @cancel="cancle" 
    wrapClassName="name" 
    :width='460'
    :closable="false"
    centered
    :maskClosable='false'
    >
    <a-icon v-if="type=='delete'" type="exclamation-circle" />
    <a-icon  v-if="type=='confirm'" type="check-circle" style="color: #1890FF"/>
    <p>
      {{info}}
    </p>
  </a-modal>
</template>
<script>
export default {
  // 示例
  // <delete-modal 
  // :visible.sync="visible"
  // info='删除提示文字'
  // @deleteHandOk="handlerok"
  // ></delete-modal>
  name:'delete-modal',
  data(){
    return{
    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
    info:{
      type:String,
      default:'确认删除商品吗？'
    },
    type:{
      type:String,
      default:'delete'
    }
  },
  methods:{
    handleOk(){
      this.$emit('update:visible',false)
      this.$emit('deleteHandOk')
    },
    cancle(){
      this.$emit('update:visible',false)
	    this.$emit('deleteHandCancle')
    }
  }
}
</script>
<style lang="less" scoped>
  /deep/ .name{
    .ant-modal-content{
      text-align:center;
      .ant-modal-body{
        padding:30px 0 0 0;
        min-height: 200px;
        .anticon{
          font-size:54px;
          color:#FF8F3C;
          padding-bottom:12px;
        }
        p{
          height: 24px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #262626;
          line-height: 24px;
          padding: 20px;
          margin-bottom: 20px;
          padding-top: 0px
        }
      }
      .ant-modal-footer{
      text-align: center;
      border-top:0px solid #fff;
      padding:24px 0 30px;
      .ant-btn{
        width: 120px;
        height:40px;
        border-radius: 4px;
        border: 1px solid #CCCCCC;
        font-size: 16px;
        color:#777;
      }
      .ant-btn-primary{
        color: #fff;
        background:#00AAA6;
        margin-left:24px;
        border: none;
      }
    }
    }
    
  }
</style>


